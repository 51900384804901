/**
 * Created by aleco on 03/14/2018.
 */

import {Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {FileUploader} from "ng2-file-upload";
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import {CsvMappingFields} from "../../../../entities/csvMappingFields";
import {ServiceService} from "../../../../services/service.service";
import {Service} from "../../../../entities/service";
import { SharedService } from 'src/app/services/sharedService';


@Component({
  selector: '<ntr-csv-upload>',
  templateUrl: './csv-upload.component.html',
  styleUrls: ['./csv-upload.component.css']
})
export class CsvUploadComponent {
  @ViewChild('fileUpload', {static: false}) selectedFile: any;
  errorMessage: string;
  @ViewChild('informationTemplate', {static: false})
  private informationTemplate: TemplateRef<any>;
  modalRef: BsModalRef;
  @Input() modal: BsModalRef;
  dataRows: string[];
  @Input()
  mappingObjects = [];
  mappingDropdown = new CsvMappingFields().dropDownList;
  public uploader: FileUploader;
  @Input()
  formatName: string;
  @Input()
  fileFormat: object;
  @Input()
  serviceId: string;
  @Input()
  service: Service;
  @Output() dataFormatsAdded = new EventEmitter();
  @Input()
  hasHeader: boolean;
  dateTypeIsSelected: boolean;
  constructor(private serviceService: ServiceService, private modalService: BsModalService, private elementRef: ElementRef, private sharedService: SharedService) {
    this.uploader = new FileUploader({url: this.sharedService.apiUrl('upload')});
    this.uploader.onAfterAddingFile = (fileItem) => {
      var fileReader = new FileReader();
      fileReader.onload = (e) => {
        if (this.uploader.queue.length > 1)
          this.uploader.queue.shift();
        this.fileFormat = null;
        this.dataRows = (fileReader.result as string).split('\n').slice(0, 5);
        this.mappingObjects = [];
        this.dataRows.slice(0, 1)[0].split(',').forEach((item, index) => {
          this.mappingObjects.push({"column": index, "name": '', "alias": '', "description": ''})
        });
      }
      fileReader.readAsText(fileItem._file, "ISO-8859-1");
    }
  }

  onChange(type, index) {
    this.mappingObjects[index].type = type;
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});

  }

  closeModal(template: TemplateRef<any>) {
    this.modalRef.hide();
  }

  apply() {
    let sampleData: any[];
    if (this.dataRows != null) {
      sampleData = [];
      for (let row of this.dataRows) {
        let rowData = row.split(",");
        let rowArray = [];
        for (let rd of rowData) {
          rowArray.push(rd)
        }
        sampleData.push(rowArray)
      }
    }
    if (this.fileFormat != null) {
      this.fileFormat['name'] = this.formatName
      this.fileFormat['hasHeader'] = this.hasHeader
    }
    else {
      this.dataRows = []
      this.fileFormat = {
        "name": this.formatName,
        "mappingObjects": this.mappingObjects,
        "sample": sampleData,
        "hasHeader": this.hasHeader
      }
    }
    this.fileFormat['serviceId'] = this.serviceId;
    this.serviceService.addFileFormat(this.fileFormat).subscribe(service => {
      this.dataFormatsAdded.emit(this.fileFormat);
      this.formatName = null;
      this.fileFormat = [];
      this.hasHeader = false;
      this.serviceService.updateService(service);
      this.closeWindow()
    }, error => {
      this.dataFormatsAdded.emit(this.fileFormat);
      this.errorMessage = 'ERRORS.' + error.code;
      this.openModal(this.informationTemplate);
    });
  }

  addNameToMapping(value, index) {
    this.mappingObjects[index].name = value;
  }

  addFormatToMapping(value, index) {
    this.mappingObjects[index]['format'] = value;
  }

  addAliasToMapping(value, index) {
    this.mappingObjects[index].alias = value;
  }

  addDescriptionToMapping(value, index) {
    this.mappingObjects[index].description = value;
  }

  clearFormData() {
    this.uploader.clearQueue();
    this.clearSelectedFile();
    this.dataRows = [];
  }

  removeFile(item) {
    this.clearFormData()
    item.remove();
  }

  closeWindow() {
    this.clearFormData()
    this.formatName = '';
    this.hasHeader = false;
    this.modal.hide();
  }

  clearSelectedFile() {
    this.selectedFile.nativeElement.value = '';
  }

}
