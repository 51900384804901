import { Component, OnInit, Input } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { FusionChart } from '../fusion-chart';
import { ExportToCsv } from 'export-to-csv';
import { ExcelService } from 'src/app/services/excel.Service';
@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent extends FusionChart implements OnInit {

  @Input() title
  @Input() footer
  @Input() description
  @Input() dashboardConfigs
  @Input() index

  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
    fill: false
  };
  constructor(public dashboardService: DashboardService, private excelService: ExcelService) {
    super(dashboardService);
    this.barChartType = 'pie';
  }

  ngOnInit() {
    this.barChartData = [{ data: [] }]
    this.dashboardConfigs['chart'] = 'pie'
    super.loadData(this.dashboardConfigs)
  }
  downloadCanvas(event) {
    // get the `<a>` element from click event
    var anchor = event.target;
    // get the canvas, I'm getting it by tag name, you can do by id
    // and set the href of the anchor to the canvas dataUrl
    //anchor.href = document.getElementsByTagName('canvas')[0].toDataURL();
    let canvas = <HTMLCanvasElement>document.getElementById("pie_" + this.index)
    anchor.href = canvas.toDataURL();
    // set the anchors 'download' attibute (name of the file to be downloaded)
    anchor.download = this.title + ".png";
  }
  downloadCSV() {
    const options = {
      filename: this.title,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: this.title,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(this.getData());
  }

  downloadExcel(): void {
    this.excelService.exportAsExcelFile(this.getData(), this.title);
  }
  getData() {
    var data = []
    this.barChartData.forEach(elem => {
      var el = {}
      el['-'] = elem.label
      this.barChartLabels.forEach((elemLabel, index) => {
        el[elemLabel] = index < elem.data.length ? elem.data[index] : 0
      })
      data.push(el);
    })
    return data;
  }
}
