/**
 * Created by aleco on 9/26/2017.
 */

import {AfterViewInit, Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Service} from "../../../entities/service";
import {ServiceService} from "../../../services/service.service";
import {ActivatedRoute, Router, ParamMap} from "@angular/router";
import {ServiceTypeDirective} from "../../../directives/service-type.directive";
import {ServiceComponent} from "../service-type-forms/service.component";
import {ServiceCsvComponent} from "../service-type-forms/csv-importer/service-csv.component";
import {ServiceMcasComponent} from "../service-type-forms/service-mcas.component";
import {ServiceOtpComponent} from "../service-type-forms/service-otp.component";
import { switchMap } from 'rxjs/operators';

@Component({
  selector: '<ntr-service-add-edit>',
  templateUrl: './service-edit.component.html',
  styleUrls: ['./service-edit.component.css']
})
export class ServiceEditComponent implements OnInit {
  @ViewChild(ServiceTypeDirective, {static: false}) serviceTypeHost: ServiceTypeDirective;
  service: Service = new Service();
  serviceTypes: Array<any> = [
    {name: 'CSV Importer', tag: 'SERVICE.CSV.IMPORTER', key: '1'},
    {name: 'XCAS Rdx Connection', tag: 'SERVICE.XCAS.CONNECTION', key: '2'},
    {name: 'Payment Service', tag: 'SERVICE.PAYMENT', key: '3'}
  ];
  submitted = false;
  _serviceType: number;
  _serviceTypeStr: string;

  constructor(public serviceService: ServiceService, private router: Router, private route: ActivatedRoute, private componentFactoryResolver: ComponentFactoryResolver) {
    serviceService.serviceUpdated$.subscribe(
      service => {
        this.service = service;
      });
  }

  onSubmit() {
    this.service.type = this.serviceTypes[this._serviceType]['key'];
    this.submitted = true;
    this.serviceService.add(this.service)
      .subscribe(service => {
        this.router.navigateByUrl('/service-list');
      });
  }

  onChange(serviceType) {
    this._serviceType = serviceType - 1;
  }


  goToList() {
    this.router.navigateByUrl('/service-list');
  }

  ngOnInit(): void {
    this.service = new Service();
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.serviceService.getService(params.get('id'))))
      .subscribe(service => {
        if (service !== null) {
          this.service = service;
          this.serviceTypes.forEach((element) => {
            if (element.key === this.service.type) {
              this._serviceType = element.key - 1;
              this._serviceTypeStr = this.service.type;
            }
          });
        }
      });
  }


}
