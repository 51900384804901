import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { DoughnutChartComponent } from './components/charts/doughnut-chart/doughnut-chart.component';
import { RadarChartComponent } from './components/charts/radar-chart/radar-chart.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import { ServiceListComponent } from './components/service/service-list.component';
import { ServiceEditComponent } from './components/service/service-edit/service-edit.component';
import { CurrencyListComponent } from './components/currency/currency-list.component';
import { CurrencyAddEditComponent } from './components/currency/currency-add-edit.component';
import { CsvListComponent } from './components/csvlist/csvlist.component';
import { DashboardConfigComponent } from './components/dashboard-config/dashboard-config.component';
import { DashboardEditComponent } from './components/dashboard-config/dashboard-edit/dashboard-edit.component';
import { BatchProcessListComponent } from './components/batch-process-manager/batch-process-list.component';
import { BatchProcessViewComponent } from './components/batch-process-manager/batch-process-view.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { UserListComponent } from './components/user/user-list.component';
import { UserAddEditComponent } from './components/user/user-add-edit/user-add-edit.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AdminComponent } from './components/admin/admin.component';
import { ContractComponent } from './components/contract/contract.component';

const routes: Routes = [
  { path: 'll_onf',  component: LandingPageComponent },
  { path: 'admin',  component: AdminComponent },
  { path: 'home',  component: HomeComponent },
  { path: 'contract',  component: ContractComponent },
  { path: 'csv-list/:id', component: CsvListComponent},
  { path: 'bar-chart', component: BarChartComponent},
  { path: 'doughnut-chart', component: DoughnutChartComponent},
  { path: 'radar-chart', component: RadarChartComponent},
  { path: 'pie-chart', component: PieChartComponent},
  { path: 'service-list',  component: ServiceListComponent, canActivate: [AuthGuard]},
  { path: 'service-add-edit', component: ServiceEditComponent, canActivate: [AuthGuard]},
  { path: 'service-add-edit/:id', component: ServiceEditComponent, canActivate: [AuthGuard]},
  { path: 'user-list',  component: UserListComponent, canActivate: [AuthGuard]},
  { path: 'user-add-edit', component: UserAddEditComponent, canActivate: [AuthGuard]},
  { path: 'user-add-edit/:id', component: UserAddEditComponent, canActivate: [AuthGuard]},
  { path: 'currency-list', component: CurrencyListComponent, canActivate: [AuthGuard]},
  { path: 'currency-add-edit', component: CurrencyAddEditComponent, canActivate: [AuthGuard]},
  { path: 'currency-add-edit/:id', component: CurrencyAddEditComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-config', component: DashboardConfigComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-add-edit', component: DashboardEditComponent, canActivate: [AuthGuard]},
  { path: 'dashboard-add-edit/:id', component: DashboardEditComponent, canActivate: [AuthGuard]},
  { path: 'batch-process-list', component: BatchProcessListComponent, canActivate: [AuthGuard]},
  { path: 'batch-process-view/:id', component: BatchProcessViewComponent, canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
