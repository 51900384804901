import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  admin: any;
  constructor(private adminService: AdminService) {
    this.admin = {}
   }

  ngOnInit() {
    this.adminService.getAdminSettings()
    .subscribe(settings => {
      this.admin = settings;
      if (this.admin===null){
        this.admin = {}
      }
    });
  }


  onSubmit() {
    this.adminService.saveAdminSettings(this.admin)
      .subscribe(settings => {
        this.admin = settings;
      });
  }

}
