import { DashboardService } from "src/app/services/dashboard.service";

export class FusionChart {

    public barChartLabels = [];
    public barChartLabelsDate = [];
    public barChartType = '';
    public barChartLegend = true;
    public dashboardConfigs
    public barChartData = null;

    constructor(public dashboardService: DashboardService) {

    }

    loadData(dashboardConfigs) {
        this.barChartLabels = [];
        this.barChartLabelsDate = [];
        this.dashboardConfigs = dashboardConfigs
        let dataMap = new Map();

        this.dashboardService.getDashboardData(this.dashboardConfigs).subscribe(dd => {
            dd.forEach(res => {
                var data = res['result']
                var resValueCol = res['valueCol']
                if (data===null){
                    return;
                }
                data.forEach(element => {
                    var res = element._id
                    if (!this.barChartLabels.includes(res['month'] ? res['month'] + '/' + res['year'] : res['year'])) {
                        this.barChartLabels.push(res['month'] ? res['month'] + '/' + res['year'] : res['year'])
                        this.barChartLabelsDate.push(res['month'] ? new Date(res['year'] + '-' + res['month'] + '-01') : res['year'])
                    }
                });
                this.barChartLabelsDate.sort(function (a, b) {
                    return a - b;
                });
                this.barChartLabels = []
                this.barChartLabelsDate.forEach(d => {
                    if (d !== null) {
                        if (typeof d.getMonth === 'function')
                            this.barChartLabels.push((d.getMonth() + 1) + '/' + d.getFullYear())
                        else {
                            this.barChartLabels.push(d)
                        }
                    }
                })
                    data.forEach(element => {
                        var res = element._id
                        var indexOfDate = this.barChartLabels.indexOf(res['month'] ? res['month'] + '/' + res['year'] : res['year'])
                        var elem = dataMap.get((res.company!==undefined?res.company+' ':'') + (res.mineral!==undefined?res.mineral+' ':'') + resValueCol)
                        if (elem) {
                            while (indexOfDate > elem['data'].length) {
                                elem['data'].push(0)
                            }
                            elem[data] = elem['data'].push(this.barChartLabels.includes(res['month'] ? res['month'] + '/' + res['year'] : res['year']) ? (element.result * 1) : 0)
                        } else {
                            let dataArr: Array<number>;
                            dataArr = [];
                            while (indexOfDate > dataArr.length) {
                                dataArr.push(0)
                            }
                            dataArr.push(element.result)
                            elem = {
                                data: dataArr, label: (res.company!==undefined?res.company+' ':'') + (res.mineral!==undefined?res.mineral+' ':'') + resValueCol
                            }
                            dataMap.set((res.company!==undefined?res.company+' ':'') + (res.mineral!==undefined?res.mineral+' ':'') + resValueCol, elem)
                        }
                    });
                    this.barChartData = []
                    dataMap.forEach(elem => {
                        if (elem.data[0]!=null){
                            if (this.dashboardConfigs.chart==='stacked')
                            this.barChartData.push({ data: elem.data as Array<number>, label: elem['label'] + '', stack: 'a' })
                            else if (this.dashboardConfigs.chart==='pie' && this.barChartLabelsDate[0]===null){
                                if (this.barChartData.length===0){
                                    var x = {}
                                    x['data'] = []
                                    this.barChartData.push(x);
                                }
                                this.barChartData[0]['data'].push(elem.data[0])
                                 
                                if (!this.barChartLabels.includes(elem['label'] + '')){
                                    this.barChartLabels.push(elem['label'])
                                }
                            }
                            else{
                               this.barChartData.push({ data: elem.data as Array<number>, label: elem['label'] + ''})
                     
                            }
                        }
                        
                    })

            });

        })

        // this.asyncForEach(this.dashboardConfigs, async (dConf) => {
        //     this.dashboardService.getDashboardData(dConf).subscribe(data => {
        //         if (data===null){
        //             return;
        //         }
        //         console.log(data)
        //         data.forEach(element => {
        //             var res = element._id
        //             if (!this.barChartLabels.includes(res['month'] ? res['month'] + '/' + res['year'] : res['year'])) {
        //                 this.barChartLabels.push(res['month'] ? res['month'] + '/' + res['year'] : res['year'])
        //                 this.barChartLabelsDate.push(res['month'] ? new Date(res['year'] + '-' + res['month'] + '-01') : res['year'])
        //             }
        //         });
        //         this.barChartLabelsDate.sort(function (a, b) {
        //             return a - b;
        //         });
        //         this.barChartLabels = []
        //         this.barChartLabelsDate.forEach(d => {
        //             if (d !== null) {
        //                 if (typeof d.getMonth === 'function')
        //                     this.barChartLabels.push((d.getMonth() + 1) + '/' + d.getFullYear())
        //                 else {
        //                     this.barChartLabels.push(d)
        //                 }
        //             }
        //         })
        //             data.forEach(element => {
        //                 var res = element._id
        //                 var indexOfDate = this.barChartLabels.indexOf(res['month'] ? res['month'] + '/' + res['year'] : res['year'])
        //                 var elem = dataMap.get((res.company!==undefined?res.company+' ':'') + (res.mineral!==undefined?res.mineral+' ':'') + dConf.valueCol)
        //                 if (elem) {
        //                     while (indexOfDate > elem['data'].length) {
        //                         elem['data'].push(0)
        //                     }
        //                     elem[data] = elem['data'].push(this.barChartLabels.includes(res['month'] ? res['month'] + '/' + res['year'] : res['year']) ? (element.result * 1) : 0)
        //                 } else {
        //                     let dataArr: Array<number>;
        //                     dataArr = [];
        //                     while (indexOfDate > dataArr.length) {
        //                         dataArr.push(0)
        //                     }
        //                     dataArr.push(element.result)
        //                     elem = {
        //                         data: dataArr, label: (res.company!==undefined?res.company+' ':'') + (res.mineral!==undefined?res.mineral+' ':'') + dConf.valueCol
        //                     }
        //                     dataMap.set((res.company!==undefined?res.company+' ':'') + (res.mineral!==undefined?res.mineral+' ':'') + dConf.valueCol, elem)
        //                 }
        //             });
        //             this.barChartData = []
        //             // dataMap.forEach(elem => {
        //             //     if (!this.barChartLabels.includes(elem['label']+'') && elem.data[0]!=null){
        //             //        this.barChartLabels.push(elem['label'] + '')
        //             //     }
        //             // })
        //             dataMap.forEach(elem => {
        //                 if (elem.data[0]!=null){
        //                     if (this.dashboardConfigs.chart==='stacked')
        //                     this.barChartData.push({ data: elem.data as Array<number>, label: elem['label'] + '', stack: 'a' })
        //                     else if (this.dashboardConfigs.chart==='pie' && this.barChartLabelsDate[0]===null){
        //                         if (this.barChartData.length===0){
        //                             var x = {}
        //                             x['data'] = []
        //                             this.barChartData.push(x);
        //                         }
        //                         this.barChartData[0]['data'].push(elem.data[0])
        //                         if (this.barChartLabels.includes(elem['label'] + '')){
        //                         }
        //                     }
        //                     else{
        //                        this.barChartData.push({ data: elem.data as Array<number>, label: elem['label'] + ''})
                     
        //                     }
        //                 }
                        
        //             })

        //             console.log(dataMap)
               
        //     }, err=> {
        //         console.log('err')
        //     })
        // })
        
    }

    async asyncForEach(array, callback) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index]);
        }
    }

}