import {Injectable} from '@angular/core';
import {SharedService} from "./sharedService";


import {PagerDataObject} from "../entities/pagerDataObject";
import {catchError, tap} from 'rxjs/operators';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { Subject, Observable, of } from 'rxjs';
import { Dashboard } from '../entities/dashboard';


const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class DashboardService {

  private dashBoardUpdate = new Subject<Dashboard>();
  dashboardUpdated$ = this.dashBoardUpdate.asObservable();

  constructor(private http: HttpClient, private sharedService: SharedService) {
  }

  // getServices(): Promise<Service[]> {
  //
  //   console.log(this.sharedService.apiUrl('api/services', ""));
  //   return this.http.get( this.sharedService.apiUrl('api/services', ""))
  //     .toPromise()
  //     .then(response => response.json() as Service[])
  //     .catch(this.handleError);
  //
  // //  return Promise.resolve(SERVICES);
  // }


  getData(query: string): Observable<PagerDataObject> {
    let dashboards$ = this.http.get<PagerDataObject>(this.sharedService.apiUrl('api/dashboards', "/" + query))
    return dashboards$;
  }

  getTotalDashboardList(query: string): Observable<Dashboard[]> {
    let dashboards$ = this.http.get<Dashboard[]>(this.sharedService.apiUrl('api/dashboards'))
    return dashboards$;
  }

  getDashboard(id: string): Observable<Dashboard> {
    let dashboard$ = this.http.get<Dashboard>(this.sharedService.apiUrl('api/dashboards/' + id))
    return dashboard$;
  }

  add(dashboard: Dashboard): Observable<Dashboard> {
    return this.http.post<Dashboard>(this.sharedService.apiUrl('api/dashboards/add'), dashboard, httpOptions).pipe(
      tap((dashboard: Dashboard) => this.sharedService.log(`added dashboards w/ id=${dashboard.id}`)),
      catchError(this.sharedService.handleError<Dashboard>('addDashboard'))
    );
  }

  delete(dashboard: Dashboard): Observable<Dashboard> {
    return this.http.post<Dashboard>(this.sharedService.apiUrl('api/dashboards/delete'), dashboard, httpOptions).pipe(
      tap((dashboard: Dashboard) => this.sharedService.log(`deleted dashboard w/ id=${dashboard}`)),
      catchError(this.sharedService.handleError<any>('deleteDashboard'))
    );
  }

 
 

  updateDashboard(dashboard: Dashboard) {
    this.dashBoardUpdate.next(dashboard);
  }

  getDashboardData(dashboardConfigs : any){ 
    return this.http.post<any>(this.sharedService.apiUrl('api/dashboards/data'), dashboardConfigs, httpOptions).pipe(
      tap((dashboard: any) => this.sharedService.log(`get dashboard data w/ id=${dashboardConfigs}`)),
      catchError(this.sharedService.handleError<any>('getDashboardData'))
    );

  }

  // getServicesSlowly(): Promise<Service[]> {
  //   return new Promise(resolve => {
  //     // Simulate server latency with 2 second delay
  //     setTimeout(() => resolve(this.getData()), 2000);
  //   });
  // }

  // getService(id: number): Promise<Service> {
  //   return this.getServices()
  //     .map(heroes => heroes.find(hero => hero.id === id));
  // }
  // getService(id: number): Observable<PagerDataObject> {
  //    return this.getData()
  //   //   .map(heroes => heroes.find(hero => hero.id === id));
  // }
  columns: Array<any> = [
    {key: 'view', style: {'flex': '0 0 100px', 'width': '1%'}, button: true},
    {name: 'Name', tag: 'DASHBOARD.NAME', key: 'name', filtering: true, sorting: true, sortingOrder: 'asc'},
    {name: 'Type', tag: 'DASHBOARD.TYPE', key: 'type', filtering: true, sorting: true}
  ];

  getMetaInfo(): string[] {
    return ['name', 'type'];
  }

  getColumns(): string[] {
    return this.columns;
  }
}
