import { Injectable, Output, EventEmitter } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable, Subject } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SharedService {
  private lan$ = new Subject<any>();

  apiUrl(pathToAPI: string, parameters = ''): string {
    return  environment.apihost + '/' + pathToAPI + '/' + parameters;
  }

  // apiUrlLogin(pathToAPI: string, parameters = ''): string {
  //   return 'http://' + environment.apihost + '/' + pathToAPI
  // }

  constructor(private http: HttpClient) {

  }

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
      return Observable.throw(error.error || 'Server error');
    };
  }

  // getAccessToken() {
  //   return environment.accessToken;
  // }


  log(message: string) {
    //this.messageService.add('HeroService: ' + message);
  }

  getLan$(): Observable<any> {
    return this.lan$.asObservable();
  }

  getLan() {
    return localStorage.getItem('default_lan');
  }

  setLan(lan) {
    localStorage.setItem('default_lan', lan);
    this.lan$.next(lan);
  }

  getAccessToken() {
    return environment.accessToken;
  }

  @Output() notifyLoginEmmiter: EventEmitter<any> = new EventEmitter();
  notifyLogin(username) {
    this.notifyLoginEmmiter.emit(username)
  }


  downloadFromS3(key: any): Observable<Blob> {

    const httpOptionsExcel = {
      headers: new HttpHeaders({ 'Accept': 'application/vnd.ms-excel', 'Content-Type': 'application/vnd.ms-excel' }),
      responseType: 'blob' as 'json',
      params: new HttpParams().set('key', key)
    };
    let process$ = this.http.get<any>(this.apiUrl('api/contract/s3'), httpOptionsExcel).pipe(
      map((res: Response) => res), catchError(this.handleError<any>('download excel'))
    )
    return process$;

  }

  // @Output() deactivateUserEmmitter: EventEmitter<User> = new EventEmitter();
  // @Output() deactivateBranchEmmitter: EventEmitter<Branch> = new EventEmitter();
  // @Output() deactivateBadgeEmmitter: EventEmitter<Badge> = new EventEmitter();
  // deactivateEntity(obj) {
  //   if (obj instanceof User || obj['class'] === 'USER') {
  //     this.deactivateUserEmmitter.emit(obj)
  //   }
  //   if (obj instanceof Branch || obj['class'] === 'BRANCH') {
  //     this.deactivateBranchEmmitter.emit(obj)
  //   }
  //   if (obj instanceof Badge || obj['class'] === 'BADGE') {
  //     this.deactivateBadgeEmmitter.emit(obj)
  //   }

  // }

  // @Output() activateUserEmmitter: EventEmitter<User> = new EventEmitter();
  // @Output() activateBranchEmmitter: EventEmitter<Branch> = new EventEmitter();
  // @Output() activateBadgeEmmitter: EventEmitter<Badge> = new EventEmitter();
  // activateEntity(obj) {
  //   if (obj instanceof User || obj['class'] === 'USER') {
  //     this.activateUserEmmitter.emit(obj)
  //   }
  //   if (obj instanceof Branch || obj['class'] === 'BRANCH') {
  //     this.activateBranchEmmitter.emit(obj)
  //   }
  //   if (obj instanceof Badge || obj['class'] === 'BADGE') {
  //     this.activateBadgeEmmitter.emit(obj)
  //   }
  // } 
  // @Output() updateUserEmmitter: EventEmitter<User> = new EventEmitter();
  // @Output() updateBranchEmmitter: EventEmitter<Branch> = new EventEmitter();
  // @Output() updateBadgeEmmitter: EventEmitter<Badge> = new EventEmitter();
  // updateEntity(obj) {

  //   if (obj instanceof User || obj['class'] === 'USER') {
  //    this.updateUserEmmitter.emit(obj)
  //   }
  //   if (obj instanceof Branch || obj['class'] === 'BRANCH') {
  //     this.updateBranchEmmitter.emit(obj)
  //   }
  //   if (obj instanceof Badge || obj['class'] === 'BADGE') {
  //     this.updateBadgeEmmitter.emit(obj)
  //   }

  // }

  // @Output() updateVisitEmmitter: EventEmitter<any> = new EventEmitter();
  // updateVisit(obj) {
  //   this.updateVisitEmmitter.emit(obj)
  // }

  // @Output() notifyStartedVisitEmmiter: EventEmitter<any> = new EventEmitter();
  // notifyStartedVisit() {
  //   this.notifyStartedVisitEmmiter.emit()
  // }
  // @Output() notifyAcceptedVisitEmmiter: EventEmitter<any> = new EventEmitter();
  // notifyAcceptedVisit() {
  //   this.notifyAcceptedVisitEmmiter.emit()
  // }
  // @Output() notifyRejectedVisitEmmiter: EventEmitter<any> = new EventEmitter();
  // notifyRejectedVisit() {
  //   this.notifyRejectedVisitEmmiter.emit()
  // }

  // @Output() notifyEndedVisitEmmiter: EventEmitter<any> = new EventEmitter();
  // notifyEndedVisit() {
  //   this.notifyEndedVisitEmmiter.emit()
  // }


  // @Output() pendingVisitNumberEmmitter: EventEmitter<boolean> = new EventEmitter();
  // setPendingVisitNumber(obj) {
  //   this.pendingVisitNumberEmmitter.emit(obj)
  // }

  // @Output() visitorBlacklistEmmiter: EventEmitter<boolean> = new EventEmitter();
  // setVisitorBlacklist(obj) {
  //   this.visitorBlacklistEmmiter.emit(obj)
  // }

  // @Output() notifyTableReloadEmmiter: EventEmitter<any> = new EventEmitter();
  // notifyTableReload() {
  //   this.notifyTableReloadEmmiter.emit()
  // }


  // @Output() notifyTableLoadEmmiter: EventEmitter<any> = new EventEmitter();
  // notifyLoadTable(obj){
  //   this.notifyTableLoadEmmiter.emit(obj)
  // }


}
