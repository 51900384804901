import {Component, Input, OnInit, AfterViewInit, Output, OnDestroy} from '@angular/core';
import {ServiceService} from "../../services/service.service";



import {PagerDataObject} from "../../entities/pagerDataObject";

import {Service} from "../../entities/service";
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/entities/user';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

 
  metaInfo: string[];
  columns: Array<any>;
  data: PagerDataObject;
  @Input()
  headers = [' ', 'Username', 'Name', 'Surname'];
  @Input()
  disableHeader = false;
  subscription: any;
  private dataSub$ = new Subject<any>();
  query: String;

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  constructor(private userService: UserService) {
    this.subscription = this.dataSub$.pipe(switchMap(filter => {
      return this.userService.getData(filter);
    }))
      .subscribe(data => {
        this.data = data;
      });
    this.metaInfo = this.userService.getMetaInfo();
    this.columns = this.userService.getColumns();
  }

  onGridQuery(query) {
    this.query = query
    this.dataSub$.next(query);
  }

  deleteUser(entity){
    let user = new User();
    user.id = entity;
    this.userService.delete(user)
      .subscribe(service => {
        this.dataSub$.next(this.query);
      });
  }

}
