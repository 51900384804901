import { Component, OnInit, Input } from '@angular/core';
import { Service } from 'src/app/entities/service';
import { ServiceService } from 'src/app/services/service.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'nav-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  @Input() contextMenu: string[];
  lang: string;
  username: string;
  initials: string;
  menuItems: string[][];
  lanList;
  services: Service[];
  loginSubscription: any;
  constructor(public serviceService: ServiceService, public auth: AuthService, public router: Router, public sharedService: SharedService) {
    const token = auth.decoder.tokenGetter()
    if (token && token !== 'undefined') {
      const decodedToken = auth.decoder.decodeToken(token);
      this.username = decodedToken.username;
    }
    this.loginSubscription = this.sharedService.notifyLoginEmmiter.subscribe(val => {
      this.username = val;
    }
    );
  }

  ngOnInit() {
    this.serviceService.getTotalServiceList("").subscribe(services => {
      this.services = services;

    });
  }

  logout() {
    this.username = ''
    this.auth.logout();
    this.router.navigate(['home']);
  }

}
