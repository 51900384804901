/**
 * Created by aleco on 9/26/2017.
 */

import {Component, Input, OnDestroy} from '@angular/core';
import {PagerDataObject} from "../../entities/pagerDataObject";
import {BatchProcessService} from "../../services/batchProcess.service";
import {BatchProcess} from "../../entities/batchProcess";
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'ntr-batch-process-list',
  templateUrl: './batch-process-list.component.html'
})
export class BatchProcessListComponent implements OnDestroy {
  metaInfo: string[];
  columns: Array<any>;
  data: PagerDataObject;
  @Input()
  headers = [' ', 'Service', 'Currency Code'];
  @Input()
  disableHeader = false;
  subscription: any;
  private dataSub$ = new Subject<any>();

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  constructor(private batchProcessService: BatchProcessService) {
    this.subscription = this.dataSub$.pipe(switchMap(filter => {
      return this.batchProcessService.getData(filter);
    }))
      .subscribe(data => {
        this.data = data;
      });
    this.metaInfo = this.batchProcessService.getMetaInfo();
    this.columns = this.batchProcessService.getColumns();
  }

  fieldChanged(e) {
    console.log('From event', e);
  }
  query: String;

  onGridQuery(query) {
    this.query = query
    this.dataSub$.next(query);
  }
  deleteBatchProcess(entity){
    let batchProcess = new BatchProcess();
    batchProcess.id = entity;
    this.batchProcessService.delete(batchProcess)
      .subscribe(batchProcess => {
        this.dataSub$.next(this.query);
      });
  }
}
