import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Role } from 'src/app/entities/role';
import { RoleName } from 'src/app/entities/util';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SharedService } from 'src/app/services/sharedService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  title = 'app';
  public singleModel: String = '1';
  public version: String = '1.0.0';
  roleNames = RoleName
  public error: string;
  public username: string;
  public password: string;

  constructor(private route: ActivatedRoute,
    private router: Router, public auth: AuthService, public sharedService: SharedService) {

  }

  ngOnInit() {
    const token = this.auth.decoder.tokenGetter()
    // if (token && token !== 'undefined') {
    //   const decodedToken = this.jwtHelper.decodeToken(token);
    //   var routePath = MenuCategories.get(decodedToken.role)[0][0]
    //   this.router.navigate([routePath]);
    // }
    // this.route.queryParamMap.subscribe(q => {
    //   this.notifParam = q['params'].notifications
    // });
  }


  login() {
    this.auth.login(this.username, this.password).subscribe(result => {
    
      this.auth.setLoginStatus(result)
      this.auth.getUserRole$().subscribe(role => {
        this.sharedService.notifyLogin(this.username);
        this.router.navigate(['home']);
      })
    },
      err => {
        if (err.status === 0) {
          this.error = 'ERRORS.CONNECTION_FAILED'
        }
        if (err.status === 401) {
          this.error = 'ERROR.WRONG_CREDENTIALS';
        }
      })
  }


}
