import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-period-range',
  templateUrl: './period-range.component.html',
  styleUrls: ['./period-range.component.css']
})
export class PeriodRangeComponent implements OnInit {


  
  @Input() dashboardConfigs
  @Output() valueChange = new EventEmitter();

  datePeriods: Array<any> = [
    { name: ' --- NONE --- ', tag: 'DASHBOARD.NONE', key: '0' },
    { name: 'Last Month', tag: 'DASHBOARD.LAST_MONTH', key: '1' },
    { name: 'Last 3 Months', tag: 'DASHBOARD.LAST_3_MONTH', key: '3' },
    { name: 'Last 6 Months', tag: 'DASHBOARD.LAST_6_MONTH', key: '6' },
    { name: 'Last Year', tag: 'DASHBOARD.LAST_YEAR', key: '12' },
    { name: 'Last 2 Years', tag: 'DASHBOARD.LAST_2_YEARS', key: '24' }
  ];

  datePeriod: string;
  dateRange: Date[];
  periodFilter: boolean;
  rangeFilter: boolean; 

  constructor() { }

  ngOnInit() {
  }

  onChangePeriod(periodValue) {
    this.dashboardConfigs.forEach(dd => {
      dd['periodFilter'] = true
      dd['datePeriod'] = periodValue
      dd['rangeFilter'] = false
      dd['dateRange'] = null
      this.dateRange = null
    })
    this.valueChange.emit(this.dashboardConfigs);
  }

  onChangeRange(rangeValue) {
    this.dashboardConfigs.forEach(dd => {
      dd['rangeFilter'] = true
      dd['dateRange'] = rangeValue
      dd['periodFilter'] = false
      dd['datePeriod'] = null
      this.datePeriod = null
    })
    this.valueChange.emit(this.dashboardConfigs);
  }

  setDateFilter(event, filterType) {
    if (event.srcElement.checked) {
      if (filterType === 'period') {
        this.periodFilter = true
        this.rangeFilter = false
      } else {
        this.rangeFilter = true
        this.periodFilter = false
      }
    } else {
      this.rangeFilter = false
      this.periodFilter = false
    }
  }

}
