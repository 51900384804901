import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/entities/user';
import { switchMap } from 'rxjs/operators';
import { ParamMap, Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-add-edit',
  templateUrl: './user-add-edit.component.html',
  styleUrls: ['./user-add-edit.component.css']
})
export class UserAddEditComponent implements OnInit {

  user: User = new User();
  submitted = false;
  wrongCurrentPassword = false;
  usernameExists = false;
  pass1 = ""
  pass2 = ""
  currentPassword: string;
  pass1Wrong = false;
  pass2Wrong = false;
  noPass = false;
  changePass = false;

  ngOnInit(): void {
    this.user = new User();
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => this.userService.getUser(params.get('id'))))
      .subscribe(user => {
        if (user !== null) {
          this.user = user;
        }
      });
  }


  constructor(public userService: UserService, private router: Router, private route: ActivatedRoute, private spinner: NgxSpinnerService) {

  }

  onSubmit() {
    this.user['user_update']=true
    if (!this.user.id){
      this.user['password']=this.pass1
    }
    this.userService.add(this.user)
      .subscribe(user => {
        if (user['error'] && user['error'] === 'USERNAME_EXISTS'){
          this.usernameExists = true;
        }
        else{
          this.submitted = true;
    
          this.goToList();
        }
        
      });
  }

  goToList() {
    this.router.navigateByUrl('/user-list');
  }

  changePassBtnClick(){
    this.changePass = true
  }
  
  
  updatePassword(){
    this.spinner.show();
      this.user['currentPassword'] = this.currentPassword
      this.user['password'] = this.pass1
      this.userService.add(this.user).subscribe(
        data => {
          this.spinner.hide();
          if (data['error'] && data['error'] === 'WRONG_PASSWORD') {
            this.wrongCurrentPassword = true;
          } else {
            this.wrongCurrentPassword = false;
            this.currentPassword = "";
            this.pass1 = "";
            this.pass2 = "";
            this.changePass = false;
            this.noPass = false;
          }
        },
        err => {
          console.log(err);
        }
      )

  }

  setPassword(firstTime) {
    if (firstTime) {
      this.noPass = true;
    }
    if (!firstTime) {
      this.changePass = true;
    }
  }
}
