/**
 * Created by aleco on 9/26/2017.
 */
/**
 * Created by aleco on 9/26/2017.
 */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from "@angular/router";
import {Location} from '@angular/common';
import {BatchProcess} from "../../entities/batchProcess";
import {BatchProcessService} from "../../services/batchProcess.service";
import { switchMap, startWith, takeWhile } from 'rxjs/operators';
import { interval } from 'rxjs';

@Component({
  selector: 'ntr-batch-process-view',
  templateUrl: './batch-process-view.component.html',
  styleUrls: ['./batch-process-view.component.css']
})
export class BatchProcessViewComponent implements OnInit, OnDestroy {
  batchProcess: BatchProcess;
  private display: boolean;

  private alive: boolean;

  constructor(private route: ActivatedRoute, private batchProcessService: BatchProcessService,
              private location: Location) {
    this.display = false;
    this.alive = true;

  }

  ngOnInit(): void {
    interval(1000).pipe(startWith(1),
    takeWhile(() => this.alive || (this.batchProcess && this.batchProcess.status == 1))) // only fires when component is alive
      .subscribe(() => {
        this.route.paramMap.pipe(
          switchMap((params: ParamMap) => this.batchProcessService.getBatchProcess(params.get('id'))))
          .subscribe(batchProcess => {
            this.batchProcess = batchProcess;
            this.alive = false;
          });
      });
  }

  ngOnDestroy() {
    this.alive = false; // switches your IntervalObservable off
  }

  goBack(): void {
    this.location.back();
  }

}
