/**
 * Created by aleco on 9/26/2017.
 */

import {Component, Input, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import {PagerDataObject} from "../../entities/pagerDataObject";
import {CurrencyService} from "../../services/currency.service";
import {Currency} from "../../entities/currency";
import { Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'ntr-currency-list',
  templateUrl: './currency-list.component.html'
})
export class CurrencyListComponent implements OnInit, AfterViewInit, OnDestroy {
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  metaInfo: string[];
  columns: Array<any>;
  data: PagerDataObject;
  @Input()
  disableHeader = false;
  subscription: any;
  private dataSub$ = new Subject<any>();
  query: String;

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  constructor(private currencyService: CurrencyService) {

    this.subscription = this.dataSub$.pipe(switchMap(filter => {
      return this.currencyService.getData(filter);
    }))
      .subscribe(data => {
        this.data = data;
      });
    this.metaInfo = this.currencyService.getMetaInfo();
    this.columns = this.currencyService.getColumns();
  }


  onGridQuery(query) {
    this.query = query
    this.dataSub$.next(query);
  }

  deleteCurrency(entity) {
    let currency = new Currency();
    currency.id = entity;
    this.currencyService.delete(currency)
      .subscribe(currency => {
        this.dataSub$.next(this.query);
      });
  }
}




