import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from './services/sharedService';
import { BaseService } from './services/base.service';
import {BsModalService, ModalModule, PaginationModule,BsDatepickerModule  } from 'ngx-bootstrap';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ChartService } from './services/chart.service';
import { ChartsModule } from 'ng2-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { DoughnutChartComponent } from './components/charts/doughnut-chart/doughnut-chart.component';
import { RadarChartComponent } from './components/charts/radar-chart/radar-chart.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import { MultiAxisChartComponent } from './components/charts/multi-axis-chart/multi-axis-chart.component';
import { StackedChartComponent } from './components/charts/stacked-chart/stacked-chart.component';
import { ServiceListComponent } from './components/service/service-list.component';
import { ServiceEditComponent } from './components/service/service-edit/service-edit.component';
import { DatatableComponent } from './components/datatable/datatable.component';
import {AddButtonComponent} from "./components/function-buttons/add-button.component";
import {SaveButtonComponent} from "./components/function-buttons/save-button.component";
import { ServiceService } from './services/service.service';
import {ServiceTypeDirective} from "./directives/service-type.directive";
import {DashboardTypeDirective} from "./directives/dashboard-type.directive";
import { CurrencyService } from './services/currency.service';
import { BankService } from './services/bank.service';
import {ServiceCsvComponent} from "./components/service/service-type-forms/csv-importer/service-csv.component";
import {ServiceMcasComponent} from "./components/service/service-type-forms/service-mcas.component";
import {CsvUploadComponent} from "./components/service/service-type-forms/csv-importer/csv-upload.component";
import {ServiceOtpComponent}  from "./components/service/service-type-forms/service-otp.component";
import {FileUploadModule} from "ng2-file-upload";
import { CurrencyListComponent } from './components/currency/currency-list.component';
import { CurrencyAddEditComponent } from './components/currency/currency-add-edit.component';
import { CsvListComponent } from './components/csvlist/csvlist.component';
import { CsvListService } from './services/csvList.service';
import { DashboardConfigComponent } from './components/dashboard-config/dashboard-config.component';
import { DashboardEditComponent } from './components/dashboard-config/dashboard-edit/dashboard-edit.component';
import { DashboardService } from './services/dashboard.service';
import {BatchProcessListComponent} from "./components/batch-process-manager/batch-process-list.component";
import {BatchProcessViewComponent} from "./components/batch-process-manager/batch-process-view.component";
import {BatchProcessService} from "./services/batchProcess.service";
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { AuthService } from './services/auth.service';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthInterceptor } from './util/auth.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { UserListComponent } from './components/user/user-list.component';
import { UserService } from './services/user.service';
import { UserAddEditComponent } from './components/user/user-add-edit/user-add-edit.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PeriodRangeComponent } from './components/charts/period-range/period-range.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AdminComponent } from './components/admin/admin.component';
import { AdminService } from './services/admin.service';
import { ContractComponent } from './components/contract/contract.component';
import { ContractService } from './services/contract.service';
import { ExcelService } from './services/excel.Service';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function tokenGetter() {
  return localStorage.getItem('rdf_fusacc_tkn');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    HeaderComponent,
    LeftMenuComponent,
    BarChartComponent,
    DoughnutChartComponent,
    RadarChartComponent,
    PieChartComponent,
    MultiAxisChartComponent,
    AddButtonComponent,
    SaveButtonComponent,
    StackedChartComponent,
    ServiceListComponent,
    ServiceEditComponent,
    DatatableComponent,
    ServiceTypeDirective,
    DashboardTypeDirective,
    ServiceCsvComponent,
    ServiceMcasComponent,
    CsvUploadComponent,
    ServiceOtpComponent,
    CurrencyListComponent,
    CurrencyAddEditComponent,
    CsvListComponent,
    DashboardConfigComponent,
    DashboardEditComponent,
    BatchProcessListComponent,
    BatchProcessViewComponent,
    UserListComponent,
    UserAddEditComponent,
    PeriodRangeComponent,
    LandingPageComponent,
    AdminComponent,
    ContractComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ChartsModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgxBootstrapIconsModule.pick(allIcons),
    BsDatepickerModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ModalModule.forRoot(),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    FileUploadModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter
      }
    })
  ],
  providers: [BsModalService, SharedService, BaseService, ChartService,
     ServiceService,CurrencyService, BankService, CsvListService, DashboardService, UserService,AdminService,ContractService,ExcelService,
     BatchProcessService, AuthService,AuthGuard, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
