/**
 * Created by aleco on 9/26/2017.
 */

import {
  AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, TemplateRef,
  ViewChildren
} from '@angular/core';

import { saveAs } from 'file-saver';
import {BsModalRef, BsModalService} from "ngx-bootstrap";
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PagerDataObject } from 'src/app/entities/pagerDataObject';
import { SharedService } from 'src/app/services/sharedService';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'ntr-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent implements OnInit, OnChanges, AfterViewInit {
  maxSize: number = 10;
  bigTotalItems: number = 0;
  bigCurrentPage: number = 1;
  numPages: number = 0;
  currentPage = 1;
  origSortColumn = '';
  origSortOrder = '';
  origSortNode = '';
  sortColumn = '';
  sortNode = '';
  sortOrder = 'desc';
  filtered = false;
  loading = true;
  @ViewChildren('filterItem') viewChildren: ElementRef[];
  @Input() public columns: Array<any>;
  @Output() onQuery: EventEmitter<any> = new EventEmitter();
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter(false);
  @Output() onDeleteQuery: EventEmitter<any> = new EventEmitter();
  searchAll: string = '';
  currentFilter: string = '';
  @Input() data: PagerDataObject;
  tableData: any;
  entity: any;
  totalCount: number = 0;
  from: number = 0;
  to: number = 0;
  @Input()
  headers = [];
  @Input()
  disableHeader = false;
  @Input()
  dataService;
  @Input()
  modelView;
  @Output()
  fieldChanged = new EventEmitter();
  @Input()
  metaInfo: string[];
  @Input()
  entityTitle: string;
  private query = new Subject<any>();
  public config: any = {
    paging: true,
    filtering: true
  };
  modalRef: BsModalRef;
  @Input()
  actionButtons: boolean = true;
  @Input()
  downloadButton: boolean = false;

  constructor(private modalService: BsModalService, private sharedService: SharedService, public auth: AuthService) {
    this.query.pipe(
      debounceTime(500))
      .subscribe(() => {
        this.setPage(1);
      });
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }

  ngOnInit(): void {
    // set Default Sort Column
    const col = this.columns.find(item => (item.sorting && item.sortingOrder));
    this.sortColumn = col ? col.key : null;
    this.sortOrder = col ? col.sortingOrder : null;
    this.sortNode = col ? col.node : null;

    Object.assign(this.origSortColumn, this.sortColumn);
    Object.assign(this.origSortOrder, this.sortOrder);
    Object.assign(this.origSortNode, this.sortNode);
    this.filter(null, '');
  }

  pageChanged(event: any): void {
    //console.log('Page changed to: ' + event.page);
    //console.log('Number items per page: ' + event.itemsPerPage);
  }

  setPage(pageNo: number): void {
    this.currentPage = pageNo;
    this.fireQueryEvent();
  }

  filter(column, value) {
    // Column set by Client
    if (column)
      this.getColumnByNodeKey(column.node, column.key).filteringString = value;
    // Search All BOX
    else
      this.searchAll = value;

    this.query.next();
  }

  fireQueryEvent() {
    var q = '?q=' + this.searchAll + '&';
    for (var c in this.columns) {
      var column = this.columns[c];
      if (column.filtering) {
        if (column.filteringString)
          q += this.getColumnForQuery(column) + '=' + column.filteringString + '&';
        else
          q += this.getColumnForQuery(column) + '=&';
      }
    }
    if (this.sortColumn) {
      // MongoDB stuff -1 DESC, 1 ASC
      var sort = this.getColumnByNodeKey(this.sortNode, this.sortColumn);
      q += 'sort=' + this.getColumnForQuery(sort) + ':' + ((this.sortOrder === 'desc') ? -1 : 1) + '&';
    }
    q += 'from=' + ((this.currentPage - 1) * this.maxSize) + '&size=' + this.maxSize;

    let filtered = false;
    this.viewChildren.forEach(item => {
      if (item.nativeElement.value != '') {
        filtered = true;
        return filtered;
      }
    });
    this.filtered = filtered;
    this.currentFilter = q;
    this.loading = true;
    this.onQuery.emit(q);
  }
  navigateToFile(location){
    if (location.includes("http") || location.includes("http") ){
      window.open(location, "_blank");
    }else{
      window.open("http://" + location, "_blank");
    }
    
  }

  getColumnByNodeKey(node: string, key: string) {
    if (node)
      return this.columns.find(item => (item.key === key && item.node === node));
    return this.columns.find(item => (item.key === key));
  }

  private getColumnForQuery(col) {
    if (col.node) {
      return col.node + '.' + col.key;
    }
    return col.key;
  }

  sortBy(column) {
    // Avoid "sorting" action columns, such as 'View'
    if (!column.sorting) return;

    // Remove sorting sign from currently sorted column
    if (this.sortColumn) {
      var oldColumn = this.getColumnByNodeKey(this.sortNode, this.sortColumn);
      oldColumn.sortingOrder = '';
    }

    if (this.sortColumn === column.key && this.sortNode === column.node) {
      if (this.sortOrder == 'desc')
        this.sortOrder = 'asc';
      else
        this.sortOrder = 'desc'
    } else {
      this.sortColumn = column.key;
      this.sortNode = column.node;
      this.sortOrder = 'asc';
    }

    // Add sorting sign from currently sorted column
    if (this.sortColumn) {
      var newColumn = this.getColumnByNodeKey(this.sortNode, this.sortColumn);
      newColumn.sortingOrder = this.sortOrder;
    }
    this.fireQueryEvent();
  }


  buttonClick(row, cell) {
    this.onButtonClick.emit({row: row, cell: cell});
  }

  changePage(event) {
    this.setPage(event.page);
  }

  ngAfterViewInit(): void {
    // this.searchTrigger.next()
  }

  ngOnChanges(changes: any): void {
    if (this.data && Object.keys(this.data).length != 0 && this.data.constructor === Object) {
      this.tableData = this.data.records;
      this.entity = this.data.entity;
      this.bigTotalItems = this.totalCount = this.data.total;
      this.from = this.data.from;
      this.to = this.data.to;
    }
  }

  confirmDeletion(entityId, entity): void {
    this.onDeleteQuery.emit(entityId);
    this.modalRef.hide();
  }

  decline(): void {
    //this.message = 'Declined!';
    this.modalRef.hide();
  }

  download(entity){
    this.sharedService.downloadFromS3(entity.key).subscribe
    (data => {
      
      const blob = data;
      const file = new Blob([blob], {});
      const filename = entity.key.substring(entity.key.indexOf("/")+1,entity.key.length)
      saveAs(file, filename);
    });
  }
}

