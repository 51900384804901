import { Component, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  isLoggedIn = false;
  title = 'Online Fusion';

  constructor(public translate: TranslateService, public auth: AuthService){
    translate.setDefaultLang('en');
    this.auth.loggedIn.subscribe(val => {
      this.isLoggedIn = val;
    })
  }

  ngOnInit() {
    this.auth.loggedIn.subscribe(val => {
      this.isLoggedIn = val;
    })
  }

}
